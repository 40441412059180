<template>
	<div class="page">

		<el-row>
			<el-col :span="24">
				<el-form :inline="true" :model="queryForm" style="margin-top: 10px;">
					<el-form-item>
						<el-input placeholder="请输入内容" v-model="queryForm.keyword" class="input-with-select">
							<el-select v-model="queryForm.type" slot="prepend" placeholder="请选择" style="width: 100px;">
								<el-option label="收件信息" value="address"></el-option>
								<el-option label="昵称" value="nickName"></el-option>
								<el-option label="订单号" value="order_num"></el-option>
								<el-option label="快递单号" value="exp_num"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-select v-model="queryForm.status" placeholder="请选择" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :value="item.value" :key="index" v-for="(item,index) in statusList"></el-option>


					</el-select>

					<el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>

					<el-button v-if="false" type="primary" icon="el-icon-upload" @click="ChooseFile">导出</el-button>


					<el-button v-if="false" type="primary" icon="el-icon-download" @click="ChooseFile">导入</el-button>
					<div style="display: none;">
						<input type="file" id="filemy" @change="uploadFiles" />

					</div>
				</el-form>
			</el-col>
		</el-row>
		<el-table :data="DataList" stripe border>
			<el-table-column prop="order_num" label="订单号" width="200">
				<template slot-scope="scope">
					<div @click="showStatus(scope.row)" title="点击查看支付状态">{{scope.row.order_num}}</div>

				</template>
			</el-table-column>
			<el-table-column prop="name" label="昵称" min-width="150">
				<template slot-scope="scope">
					<div v-if="scope.row.nickName">
						<div>
							<img v-if="scope.row.avatarUrl" :src="scope.row.avatarUrl" style="width: 30px; height: 30px;" onclick="viewImage(this)" />
							<div style="display: inline-block; vertical-align: top; line-height: 30px;">
								{{scope.row.nickName}}
							</div>
						</div>


					</div>

				</template>
			</el-table-column>
			<el-table-column prop="name" label="收件人" min-width="150">
				<template slot-scope="scope">
					<div v-if="scope.row.address">
						<div>
							{{scope.row.address.consignee}} {{scope.row.address.contact_number}}
						</div>

						<div>
							{{scope.row.address.province+' '+scope.row.address.city+' '+scope.row.address.area+' '+scope.row.address.contact_address}}
						</div>
					</div>

				</template>
			</el-table-column>

			<el-table-column prop="money" label="总金额" width="120">
				<template slot-scope="scope">
					{{scope.row.coin}}积分+{{scope.row.money}}元

				</template>
			</el-table-column>

			<el-table-column prop="id" label="状态" width="100">
				<template slot-scope="scope">
					<div style="text-align: center;" v-html="getStatus(scope.row.status)"></div>

				</template>
			</el-table-column>
			<el-table-column prop="id" label="购买商品" min-width="200">
				<template slot-scope="scope">

					<div style="color: #2C3E50;" v-for="(good,idx) in scope.row.goods">
						<div style="border-bottom: 1px solid #ddd;">
							{{good.goods_name}}【{{good.spec_name}}:{{good.size_name}} 】【¥{{good.size_coin}}*{{good.num}}】
						</div>

					</div>

				</template>
			</el-table-column>

			<el-table-column prop="id" label="下单时间" width="180">
				<template slot-scope="scope">
					{{scope.row.cdate}}

				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">

					<el-button type="text" @click="viewOrder(scope.row)">详情</el-button>

				</template>
			</el-table-column>
		</el-table>
		
		<el-dialog title="订单详情" :visible.sync="dialogVisible">
			<div v-if="EditOrder">
				<div v-if="EditOrder.address">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<th colspan="2">收件人信息</th>
						</thead>
						<tbody>
							<tr>
								<td style="width: 100px;">收件人信息</td>
								<td>{{EditOrder.address.consignee}}-{{EditOrder.address.contact_number}}</td>
							</tr>
							<tr>
								<td style="width: 100px;">收件地址</td>
								<td>{{EditOrder.address.province}} {{EditOrder.address.city}}
									{{EditOrder.address.area}} {{EditOrder.address.contact_address}}
								</td>
							</tr>
						</tbody>

					</table>
				</div>
				<div style="padding-top: 20px;" v-if="EditOrder.remark">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<th colspan="2">备注</th>
						</thead>
						<tbody>
							<tr>
								<td style="width: 100px;">用户留言</td>
								<td>{{EditOrder.remark}}</td>
							</tr>

						</tbody>

					</table>
				</div>
				<div style="padding-top: 20px;">
					<table v-if="(EditOrder.status==2||EditOrder.status==3||EditOrder.status==4)" style="background: #F4F4F5;" width="100%"
					 cellspacing="1">
						<thead>
							<th colspan="2">发货信息</th>
						</thead>
						<tbody>
							<tr>
								<td style="width: 100px;">快递公司</td>
								<td>{{EditOrder.exp_name}}</td>
							</tr>
							<tr>
								<td style="width: 100px;">快递单号</td>
								<td>
									<a :href="'http://m.kuaidi100.com/result.jsp?nu='+EditOrder.exp_num" target="_blank">{{EditOrder.exp_num}}</a>
								</td>
							</tr>
						</tbody>

					</table>
					<div v-if="EditOrder.status==1">
						<el-form :inline="true" :model="queryForm" style="margin-top: 10px;">
							<el-form-item label="快递公司" width="300">
								<el-input v-model="express_name" placeholder="请输入快递公司">

								</el-input>
							</el-form-item>
							<el-form-item label="快递单号" width="300">
								<el-input v-model="express_no" placeholder="请输入快递单号">

								</el-input>
							</el-form-item>
							<el-button type="primary" @click="sendExpress">发货</el-button>

						</el-form>
					</div>
				</div>
				<div style="padding-top: 20px;" v-if="EditOrder.activity">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<tr>
								<th colspan="2">优惠信息</th>
							</tr>

						</thead>
						<tbody>
							<tr>
								<td style="width: 25%;">赠送物品</td>
								<td style="width: 75%;">{{EditOrder.activity}}</td>
							</tr>


						</tbody>


					</table>
				</div>
				<div style="padding-top: 20px;">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<tr>
								<th colspan="4">商品信息【{{EditOrder.order_num}}】<span style="float: right; color: orangered;">¥{{EditOrder.money}}</span></th>
							</tr>

						</thead>
						<tbody>
							<tr v-for="(good,gidx) of EditOrder.goods">
								<td style="width: 50px;">
									<img :src="good.spec_image" style="width: 50px; height: 50px; vertical-align: middle;" />
								</td>
								<td style="width: 25%;">{{good.goods_name}}</td>
								<td style="width: 25%;">{{good.spec_name}}-{{good.size_name}}</td>
								<td style="width: 25%;">{{good.size_coin}}*{{good.num}}</td>

							</tr>

						</tbody>
						<tfoot>
							<tr>
								<td colspan="4">
									<div style="float: left;">状态：

										<div style="float: right; margin-left: 10px; margin-right: 10px; cursor: pointer; color: #8E8E8E;" @click="zhudongtuikuan=true">退款</div>

										<div style="float: right;" v-html="getStatus(EditOrder.status)"></div>
									</div>
									<div style="float: right;">{{EditOrder.created_at}}</div>
									<div style="float: right;" v-if="EditOrder.sys_remark">{{EditOrder.sys_remark.water_tickets?'使用水票 x'+EditOrder.sys_remark.water_tickets:''}}</div>
								</td>
							</tr>

						</tfoot>

					</table>
				</div>


				<div style="padding-top: 20px;" v-if="EditOrder.status==5||EditOrder.status==6">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<tr>
								<th colspan="4">退款信息【{{EditOrder.status==5?'用户申请退款':''}}{{EditOrder.status==6?'退款成功':''}}</th>
							</tr>

						</thead>
						<tbody>
							<tr>
								<td style="width: 25%;">退款金额</td>
								<td style="width: 75%;">{{EditOrder.money}}</td>


							</tr>
							<tr>
								<td style="width: 25%;">退款原因</td>
								<td style="width: 75%;"></td>


							</tr>

              <tr>
              	<td style="width: 25%;">拒绝原因</td>
              	<td style="width: 75%;">
                <el-input v-model="refundinfo.reason" placeholder="拒绝退款时输入"></el-input>
              	</td>


              </tr>

						</tbody>
						<tfoot v-if="EditOrder.status==5">
							<tr>
								<td colspan="2">
									<el-button @click="refundConfirm('refuse')">拒绝退款</el-button>
									<el-button @click="refundConfirm('ok')">确认退款</el-button>
								</td>
							</tr>

						</tfoot>

					</table>
				</div>
				<div style="padding-top: 20px;" v-if="zhudongtuikuan">
					<table style="background: #F4F4F5;" width="100%" cellspacing="1">
						<thead>
							<tr>
								<th colspan="4">退款信息</th>
							</tr>

						</thead>
						<tbody>
							<tr>
								<td style="width: 25%;">订单金额</td>
								<td style="width: 75%;">{{EditOrder.money}}</td>


							</tr>
							<tr>
								<td style="width: 25%;">退款金额</td>
								<td style="width: 75%;">

									<el-input v-model="refundinfo.money" placeholder="退款金额,不能超过总金额">

									</el-input>
								</td>


							</tr>
							<tr>
								<td style="width: 25%;">退款原因</td>
								<td style="width: 75%;">
									<el-input v-model="refundinfo.reason" placeholder="退款原因">

									</el-input>
								</td>


							</tr>

						</tbody>
						<tfoot>
							<tr>
								<td colspan="2">

									<el-button @click="refundConfirm('ok')">确认退款</el-button>
									<el-button @click="zhudongtuikuan = false">取消</el-button>
								</td>
							</tr>

						</tfoot>

					</table>
				</div>



			</div>
		</el-dialog>


		<el-dialog title="订单支付状态查询" :visible.sync="paystateVisible" @close="paystateVisible=false">
			<div>
				<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 10000}" placeholder="请输入内容" v-model="payStateMsg">
				</el-input>
				<div style="margin-top: 10px; text-align: center;">
					<el-button v-if="EditOrder&&EditOrder.status=='unpaid'" @click="setPayStatus()">改为已支付</el-button>
				</div>

			</div>
		</el-dialog>

	</div>
</template>

<script>

	export default {
		components: {
			
		},
		data() {
			return {
				queryForm: {
					type: "",
					keyword: "",
					status: ""
				},
				dialogVisible: false,
				paystateVisible: false,
				payStateMsg: "",
				DataList: [],
				EditOrder: null,
				page: {
					current_page: 1,
					next_page: 1,
					pre_page: 1,
					total_page: 1,
					count: 0
				},
				product_catalog: [],
				express_no: "",
				express_name: "",
				refund: {
					"id": 0,
					"order_id": 0,
					"money": "",
					"reason": "",
					"image": null,
					"created_at": "",
					"updated_at": "",
					result: ""
				},
				product_page: 0,
				ProductDataList: [],
				zhudongtuikuan: false, //主动退款
				refundinfo: {
					money: 0,
					reason: ""
				},
				SpecList: [],
				SizeList: [],
				good_id: "",
				spec_id: "",
				sizeid: "",

				statusList: [{
						name: "未支付",
						value: "0"
					},
					{
						name: "待发货",
						value: "1"
					},
					{
						name: "已发货",
						value: "2"
					},
					{
						name: "待评价",
						value: "3"
					},
					{
						name: "已完成",
						value: "4"
					},
					{
						name: "申请退款",
						value: "5"
					},
					{
						name: "已退款",
						value: "6"
					},
					{
						name: "已取消",
						value: "-1"
					}
				]

			}
		},

		mounted() {

			this.query();


			//this.getProductList();
		},

		methods: {


			paginate(val) {
				this.query(val)
			},
			search() {
				this.query(1)
			},
			query(page = 1) {
				let para = {
					page: page,
				}
				if (this.queryForm.type) {
					para[this.queryForm.type] = this.queryForm.keyword
				}
				if (this.queryForm.status) {
					para.status = this.queryForm.status
				}
				this.$http.post('/api/order_getList', para).then(res => {
					for (let item of res.data.data) {
						try {
							item.goods = JSON.parse(item.goods);
							if (item.address) {
								item.address = JSON.parse(item.address);
							}
							if (item.sys_remark) {
								item.sys_remark = JSON.parse(item.sys_remark);
							}




						} catch (e) {
							//TODO handle the exception
						}

					}

					this.DataList = res.data.data;
					this.page = res.data.page;
				});
			},
			update(item) {

				sessionStorage.setItem("product", JSON.stringify(item))

				this.$router.push("/proedit/" + item.id)
			},
			showStatus(item) {


				this.EditOrder = item;
				this.$http.post('/orders/check', {
					order_number: item.order_number
				}).then(res => {

					this.payStateMsg = js_beautify(JSON.stringify(res.data))
					this.paystateVisible = true;
					// 					this.$message({
					// 						type: 'success',
					// 						//message: res.data.msg+"【"+res.data.res.trade_state+res.data.res.trade_state_desc+"】",
					// 						message: JSON.stringify(res.data)
					// 					});
				})
			},
			setPayStatus() {
				this.$confirm('确认修改为已付款？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post('/orders/change-to-paid', {
						order_number: this.EditOrder.order_number,
					}).then(res => {
						this.paystateVisible = false;
						this.query(this.page.current_page);
						this.$message({
							type: 'success',
							message: '修改成功'
						});
					})

				}).catch(() => {

				});
			},

			viewOrder(item) {
				this.dialogVisible = true;
				this.EditOrder = item;

				this.refundinfo.money = item.money;
				this.refundinfo.reason = "";








			},
			sendExpress() {
				if (this.express_no) {
					this.$http.post('/admin/order_express', {
						id: this.EditOrder.id,
						exp_name: this.express_name,
						exp_num: this.express_no,
						openId: this.EditOrder.openId,

					}).then(res => {

						this.EditOrder.exp_name = this.express_name;
						this.EditOrder.exp_num = this.express_no;
						this.EditOrder.status = 2;
						console.log(this.EditOrder)
					});
				}

			},
			refundConfirm(pass) {
				this.$confirm('确认' + (pass == 'ok' ? '退款' : '拒绝退款') + '?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post('/admin/refund', {
						id: this.EditOrder.id,
						reason: this.refundinfo.reason,
						pass: pass=='ok'?true:false,
						money: this.refundinfo.money
					}).then(res => {
						this.dialogVisible = false;
						this.zhudongtuikuan = false;
						this.query(this.page.current_page);
            if(pass=='ok'){
              this.$message({
              	type: 'success',
              	message: '退款成功'
              });
            }else{
              this.$message({
              	type: 'success',
              	message: '拒绝退款成功，订单状态已修改正常！'
              });
            }

					})

				}).catch(() => {

				});

			},
			getStatus(s) {

				switch (s) {
					case 0:
						return "<span style='color:gray'>未支付</span>"
						break;
					case 1:
						return "<span style='color:red'>已支付</span>"
						break;
					case 2:
						return "<span style='color:green'>已发货</span>"
						break;
					case 3:
						return "<span style='color:gray'>待评价</span>"
						break;
					case 4:
						return "<span style='color:gray'>已完成</span>"
						break;
					case 5:
						return "<span style='color:orange'>申请退款</span>"
						break;
					case 6:
						return "<span style='color:gray'>已退款</span>"
						break;



				}
			},
			ChooseFile() {
				///document.getElementById("filemy").click()
			},
			uploadFiles(e) {


			},
			saveRemark() {
				if (this.EditOrder.remark2) {
					this.$http.post('/orders/remark', {
						id: this.EditOrder.id,
						remark: this.EditOrder.remark2,
					}).then(res => {

						this.query(this.page.current_page);
						this.$message({
							type: 'success',
							message: '保存成功'
						});
					})
				}
			}

		}
	}
</script>
<style scoped>
	table thead th {
		font-weight: bold;
		padding: 4px 10px;
		text-align: left;
	}

	table tfoot td {
		font-weight: bold;
		padding: 4px 10px;
		text-align: left;
	}

	table td {
		background: #fff;
		padding: 8px 10px;
	}
</style>
